.navigation {
  position: fixed;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 10;

  .menu {
    ul {
      margin: 0 auto;

      li {
        float: left;

        &:first-child {
          a {
            display: none;
            margin: 0 3.5rem;
            img {
              width: 80px;
            }
          }
        }
        a {
          margin: 0.3rem 3.5rem;
          color: $white;
          display: block;
          font-size: 1.3rem;
          &:hover {
            color: get-color(warning);
          }
        }
      }
    }
  }
}

@include breakpoint(medium down) {
  .navigation {
    position: fixed;
    z-index: 999;
    top: 4%;
    left: 6%;
    background-color: transparent !important;

    .btn-menu {
      width: 30px;
      height: 25px;
      display: block;
      position: fixed;
      z-index: 9999;
      cursor: pointer;

      .sandwich {
        $width: 30px;
        $height: 3px;
        position: absolute;
        background-color: $light-gray;
        width: $width;
        height: $height;
        transition: all 0.5s ease;

        &:before {
          content: '';
          position: absolute;
          width: $width;
          top: -10px;
          height: $height;
          background-color: $light-gray;
          transition: all 0.5s ease;
        }
        &:after {
          content: '';
          position: absolute;
          width: $width;
          height: $height;
          top: 10px;
          background-color: $light-gray;
          transition: all 0.5s ease;
        }
      }
    }

    &.expanded {
      .sandwich {
        background: transparent;
        transition: all 0.5s ease;

        &:before {
          transform: rotate(-45deg);
          transition: all 0.5s ease;
          top: 0;
        }
        &:after {
          transform: rotate(45deg);
          transition: all 0.5s ease;
          top: 0;
        }
      }
    }

    .menu {
      background-color: $black;
      width: 32%;
      height: 100%;
      position: fixed;
      top: 0%;
      left: -100%;
      z-index: 999;
      @include breakpoint(medium down) {
        border-left: none;
      }
      transition: left 0.5s ease;

      @include breakpoint(xxlarge) {
        width: 22%;
        left: -22%;
      }
      @include breakpoint(medium down) {
        width: 100%;
      }
      ul {
        width: 100%;
        @include vertical-center;
        li {
          clear: left;
          &:first-child {
            margin-top: 2rem;
            @include breakpoint(medium down) {
              margin-top: 0;
            }
          }
        }

        a {
          display: block;
          padding: 2rem;
          font-size: 1.5rem;
          color: $white;
          &:hover {
            background-color: lighten($black, 10%);
          }
          @include breakpoint(medium down) {
            padding: 2rem 1rem;
          }
        }
      }
    }

    &.expanded {
      .menu {
        transition: left 0.5s ease;
        left: 0;
      }
    }

    .flag {
      position: fixed;
      z-index: 997;
      width: 30px;
      display: inline-block;
      &-fr {
        top: 5%;
        right: 3%;
      }
      &-gb {
        top: 5%;
        right: 6.5%;
        @include breakpoint(xxlarge) {
          right: 5.5%;
        }
        @include breakpoint(medium down) {
          right: 13%;
        }
      }
    }
  }
}
