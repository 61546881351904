$types: (
  m: margin, 
  p: padding
);

$positions: (
  t: top, 
  b: bottom
);

$sizes: (
  s: 0.5,
  m: 1,
  l: 2,
  xl: 4,
  xxl: 6,
  3xl: 8,
  4xl: 10,
  5xl: 14,
  6xl: 18
);

@each $t, $type in $types {
  @each $p, $position in $positions {
    @each $s, $size in $sizes {
      .#{$t}-#{$p}-#{$s} {
        #{$type}-#{$position}: #{$size}rem;
      }
    }
  }
}
 
/* @include breakpoint(medium down) {
  .p-t-xxxxl {
    padding-top: 8rem;
  }

  .p-b-xxxxl {
    padding-bottom: 8rem;
  }

  .p-t-xxxl {
    padding-top: 6rem;
  }

  .p-b-xxxl {
    padding-bottom: 6rem;
  }

  .m-t-xl {
    margin-top: 2rem;
  }

  .m-t-xxxl {
    margin-top: 5rem;
  }

  p {
    font-size: 1.3rem;
  }
} */
