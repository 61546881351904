.fa-heart{
    width:15px;
    position:relative;
    top:2px;
    fill: get-color(warning);
}

.copyright{
    color:#fff;
    text-align:center;
    padding-top:2rem;
    padding-bottom:2rem;
    a{
        color:#fff;
        text-decoration: underline;
    }
    @include breakpoint(medium down) {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

