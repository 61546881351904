.bold{
  font-weight: bold;
}

.lead-font-size{
  font-size: $lead-font-size;
}

.small-font-size{
  font-size: $small-font-size;
}

.large-font-size{
  font-size: $global-font-size * 1.2;
}

.xlarge-font-size{
  font-size: $global-font-size * 5;
}

.absolute{
  position:absolute;
  z-index: 1;
}

.relative{
  position: relative;
}

.float-right{
  float:right;
}

.block{
  display: block;
}

.underline{
  text-decoration:underline;
}

.italic{
  font-style: italic;
}

.light{
  font-weight: normal;
}