body {
  color: get-color(primary);
}

[data-animate] {
  opacity: 0;
}

@include breakpoint(medium down) {
  [data-animate],
  [data-menu-reveal],
  [data-link-scroll] li a {
    opacity: 1;
  }
}

.logo {
  width: 380px;
}

.font-secondary {
  font-family: $secondary-body-font-family;
}

.services {
  color: $white;
}

.title {
  &--underline {
    &:after {
      content: '';
      display: block;
      margin: 1.7rem auto;
      width: 100px;
      height: 4px;
      background-color: get-color(primary);
    }
  }
}

.bkg-light {
  background-color: $light-gray;
}

.bkg-medium {
  background-color: get-color(primary);
}

.bkg-atouts {
  background: $light-gray
    url('../images/lechatandco-gestion-administrative.jpg') top right no-repeat;
  @include breakpoint(xlarge down) {
    background-position-x: 200%;
  }
  @include breakpoint(medium down) {
    background: $light-gray;
  }
}

.bkg-mission {
  background: url('../images/accompagnement-tpe-pme.jpg') no-repeat center;
  background-size: 100%;
  @include breakpoint(medium down) {
    background-size: cover;
  }
}

.bkg-lechatandco {
  background: url('../images/lechatandco-administratif.jpg') no-repeat bottom
    center;
  height: 950px;
  background-size: cover;
}

.presentation {
  height: 335px;
  @include breakpoint(medium down) {
    height: 800px;
  }
}

.baseline {
  color: $white;
  text-align: justify;
  font-size: 1.1rem;
}

.portrait {
  text-align: right;
  @include breakpoint(xxlarge) {
    text-align: left;
  }
  img {
    position: relative;
    top: -40px;
  }
  @include breakpoint(medium down) {
    text-align: center;
  }
}

.goals {
  background-color: rgba(0, 53, 87, 0.9);
  color: $white;
  padding: 3rem 2rem;
  height: 250px;
  .title {
    color: get-color(warning);
    text-align: center;
    font-style: italic;
    margin-bottom: 2rem;
    font-weight: normal;
  }
}

.prestation {
  color: $white;
  perspective: 1000px;
  width: 100%;
  @include breakpoint(large up) {
    height: 200px;
  }
  .cards {
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;

    @include breakpoint(large up) {
      &:hover h4 {
        font-size: 1.55rem;
        transition: font-size 1s;
      }
      &:hover img {
        //transform: rotateY(180deg);
        transition: transform 1s;
        transform: scale(1.2);
      }
    }

    @include breakpoint(large up) {
      &__face {
        width: 100%;
        height: 100%;
        padding: 3rem 1.5rem;
        transition: transform font-size 1s;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        position: absolute;
        img {
          width: 70px;
          transition: transform 1s;
        }

        h4 {
          transition: font-size 1s;
        }

        &--front {
          background-color: get-color(primary);
        }
        &--back {
          width: 100%;
          height: 100%;
          position: absolute;
          transition: transform 1s;
          transform-style: preserve-3d;
          background-color: get-color(warning);
          transform: rotateY(180deg);
          backface-visibility: hidden;
          height: 200px;
          padding: 3rem 1.5rem;
          .items {
            font-size: 0.85rem;
            text-align: left;
            line-height: 1.4;
            li {
              margin-bottom: 0.2rem;
              list-style-type: square;
            }
          }
        }
      }
    }
    @include breakpoint(medium down) {
      &__face {
        padding: 3rem 1.5rem;
        &--front {
          background-color: get-color(primary);
        }

        &--back {
          width: 100%;
          height: 100%;
          background-color: get-color(warning);
          padding: 3rem 1.5rem;
          .items {
            qfont-size: 1rem;
            text-align: left;
            line-height: 1.4;
            li {
              margin-bottom: 0.2rem;
              list-style-type: square;
            }
          }
        }
      }
    }
  }
}

.atout {
  span {
    font-size: 1.3rem;
    font-weight: bold;
  }
}
