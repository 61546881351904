@each $name, $color in $foundation-palette {
    .color-#{$name} {
      color: $color;
    }
}

.color-black{
  color:$black;
}

.color-white{
  color:$white;
}