input[type="text"], input[type="tel"], input[type="email"], textarea{
    border-bottom:1px solid get-color(warning);
    border-radius: 0;
    font-weight: bold;
    margin-top:2rem;
    &:focus{
      border-bottom: 1px solid darken(#5bb084, 20%);
    }
}
  
input[type="submit"]{
    color:#fff!important;
}
  
.form-error{
    text-align: left;
    font-size: 0.9rem;
}